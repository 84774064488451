import { actionTypes } from '../actions/actionTypes/expenseActionType';

let recieptMockData = {
  Amount: '',
  Date: null,
  index: 0,
  disableWhileRecieptCall: false,
};

let initialState = {
  individualExpenses: [],
  maxLineItems: 1,
  individualCompleteExpence: {},
  convertedCurrency: '',
  convertLoader: false,
  resetFormData: false,
  Employeeloginexists: false,
  teamCompleteExpences: [],
  caseStatus: [],
  pagination: 0,
  categories: [],
  currencies: [],
  baseCurrency: '',
  projects: [],
  showLoaderForNewExpense: false,
  teamProjects: [],
  showLoaderForOldExpense: false,
  showLoaderForTeamExpense: false,
  showLoaderForTeamExpenseStatus: {},
  getRecieptsData: [{ ...recieptMockData }],
  postTeamData: {},
  expenseCaseID: '',
  expensesResubmitData: {},
  resubmitLoader: false,
  expensesWithdrawSucess: false,
  expensesResubmitSucess: false,
  expenseType: 'ManualExpense',
  generateTokenLoader: false,
  FastLinkURL: '',
  accessToken: '',
  linkAccountLoader: false,
  IsYodleeOpen: false,
  yodleeTransactions: [],
  getTransactionsLoader: false,
  selectedTransactions: [],
  oldSelectionModel: [],
  IsYodleeInitiated: false,
  linkedAccounts: [],
  getAccountsLoader: false,
  SelectedAccount: [],
  expenseLineItems: [],
  AccountsData: {},
  OpenBankingExists: false,
  businessReason: '',
  registerUserLoader: false,
  project: '',
  YodleeExpCategoryDetails: [],
  DefaultExpCategory: {},
  imageData: [],
  attachmentsData: [],
  downloadImageLoader: false,
  pyNote: '',
  ExpenseAuditHistory: [],
  expenseAuditLoader: false,
  ResubmissionNotes: '',
  paymentRetryLoader: false,
  expenseEditSave: false,
  yodleeTransactionsAttachments: {},
  PageNumber: 1,
};

/**
 * Redux reducers.
 * Used to update state in the store after actions are issued.
 */
export function expenses(state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET_OLDEXPENSES_SUCCESS:
      return {
        ...state,
        individualExpenses: action.expensesData?.CaseInfo || [],
        maxLineItems: parseInt(action?.expensesData?.MaximumFileUploadCount || 1),
        pyNote: action.expensesData?.pyNote || '',
        expenseType: 'ManualExpense',
        expenseLineItems: [],
        Employeeloginexists:
          action?.expensesData?.Employeeloginexists === 'true' ? true : false,
        caseStatus: action?.expensesData?.CaseStatus,
        pagination: parseInt(action.expensesData?.PageIndexCount),
        OpenBankingExists: action.expensesData?.OpenBankingExists === 'true' ? true : false,
        expensesWithdrawSucess: false,
        expensesResubmitSucess: false,
        expensesResubmitData: {},
        businessReason: '',
        project: '',
        YodleeExpCategoryDetails: action?.expensesData?.YodleeExpCategoryDetails || [],
        DefaultExpCategory: action?.expensesData?.DefaultExpCategory || {},
        categories: action.expensesData?.ExpCategory,
        currencies: action.expensesData?.CurrencyList,
        baseCurrency: action.expensesData?.basecurrency,
        projects: action?.expensesData?.ProjectsList || [],
        showLoaderForOldExpense: false,
        SelectedAccount: [],
        AccountsData: {},
      };

    case actionTypes.GET_OLDEXPENSES_FAILURE:
      return {
        ...state,
        individualExpenses: [],
        caseStatus: [],
        pagination: 0,
        categories: [],
        currencies: [],
        baseCurrency: 0,
        projects: [],
        showLoaderForOldExpense: false,
      };

    case actionTypes.SHOW_LOADER:
      return {
        ...state,
        convertLoader: true,
        resetFormData: false,
      };
    case actionTypes.SHOW_LOADER_FOR_GETTING_OLD_EXPENSE:
      return {
        ...state,
        showLoaderForOldExpense: true,
      };

    case actionTypes.CONVERTED_TOTAL_CURRENCY_SUCCESS:
      return {
        ...state,
        convertedCurrency: action.cases,
        convertLoader: false,
        resetFormData: false,
      };

    case actionTypes.CONVERTED_TOTAL_CURRENCY_FAILURE:
      return {
        ...state,
        convertLoader: false,
        resetFormData: false,
      };

    case actionTypes.POST_INDIVIDUALEXPENSE_SUCCESS:
      let newData =
        state.individualExpenses && state.individualExpenses.length > 0
          ? [action.cases && action.cases[0], ...state.individualExpenses]
          : [action.cases && action.cases[0]];

      return {
        ...state,
        individualExpenses: newData,
        businessReason: '',
        project: '',
        resetFormData: action?.cases?.reset ? false : true,
        convertedCurrency: { Result: '', data: [] },
        showLoaderForNewExpense: false,
        getRecieptsData: [{ ...recieptMockData }],
      };

    case actionTypes.CLEAR_RECIPT_DATA:
      return {
        ...state,
        getRecieptsData: [{ ...recieptMockData }],
        resetFormData: false,
      };

    case actionTypes.POST_INDIVIDUALEXPENSE_FAILURE:
      return {
        ...state,
        showLoaderForNewExpense: false,
      };

    case actionTypes.SHOW_LOADER_FOR_ADDING_NEW_EXPENSE:
      return {
        ...state,
        showLoaderForNewExpense: true,
      };
    case actionTypes.GET_TEAM_EXPENESES_SUCCESS:
      return {
        ...state,
        teamCompleteExpences: (action.cases.data1 && action.cases.data1.CaseInfo) || [],
        teamProjects:
          action.cases.data1.ProjectsList &&
          action.cases.data1.ProjectsList.map((project) => project.ProjectID),
        pagination: (action.cases.data1 && parseInt(action.cases.data1.PageIndexCount)) || 1,
        showLoaderForTeamExpense: false,
      };
    case actionTypes.GET_TEAM_EXPENESES_FAILURE:
      return {
        ...state,
        teamCompleteExpences: [],
        teamProjects: [],
        pagination: 0,
        showLoaderForTeamExpense: false,
      };

    case actionTypes.SHOW_LOADER_FOR_GETTING_TEAM_EXPENSE:
      return {
        ...state,
        showLoaderForTeamExpense: true,
      };

    case actionTypes.POST_TEAM_STATUS_LOADER:
      return {
        ...state,
        showLoaderForTeamExpenseStatus: {
          ...state.showLoaderForTeamExpenseStatus,
          [action.id]: true,
        },
      };

    case actionTypes.POST_TEAM_STATUS:
      return {
        ...state,
        postTeamData: action.cases.data1,
        pagination: parseInt(action.expensesData?.PageIndexCount) || 0,
        showLoaderForTeamExpenseStatus: {},
      };
    case actionTypes.POST_TEAM_FAILURE:
      return {
        ...state,
        showLoaderForTeamExpenseStatus: {},
      };

    case actionTypes.DISABLE_WHILE_RECIEPT_CALL:
      let localRecieptsCopy = [...state.getRecieptsData];

      var getId = localRecieptsCopy.findIndex(function (item) {
        return parseInt(item.index) === parseInt(action.itemTobeDisabled.index);
      });

      if (getId === -1) {
        localRecieptsCopy.push({
          ...recieptMockData,
          disableWhileRecieptCall: true,
          index: parseInt(action.itemTobeDisabled.index),
        });
      } else {
        localRecieptsCopy[
          parseInt(action.itemTobeDisabled.index)
        ].disableWhileRecieptCall = true;
      }

      return {
        ...state,
        getRecieptsData: [...localRecieptsCopy],
      };

    case actionTypes.UPDATE_OR_DELETE_LINEITEMS: {
      let localRecieptsCopy = [...state.getRecieptsData];
      let actionIndex = action.params.index;

      if (
        action.params.actionType === 'update' &&
        localRecieptsCopy[actionIndex] &&
        action.params.type
      ) {
        localRecieptsCopy[actionIndex][action.params.type] = action.params.value;
      } else {
        localRecieptsCopy = localRecieptsCopy.filter(
          (item, index) => parseInt(index) !== actionIndex
        );
      }

      localRecieptsCopy.map((item, index) => (item.index = index));

      return {
        ...state,
        getRecieptsData: [...localRecieptsCopy],
      };
    }

    case actionTypes.GET_RECIEPTDATA_SUCCESS: {
      const setOrUpdateReciepts = () => {
        let localRecieptsCopy = [...state.getRecieptsData];
        var getId = localRecieptsCopy.findIndex(function (item) {
          return parseInt(item.index) === parseInt(action.recieptData.index);
        });

        let obj = {
          Amount: action?.recieptData?.Amount || '',
          Date: action?.recieptData?.DateAsText || null,
          index: action.recieptData.index,
          isOCRdone: true,
          disableWhileRecieptCall: false,
        };

        if (getId === -1) {
          localRecieptsCopy.push(obj);
        } else {
          localRecieptsCopy[getId] = obj;
        }
        return localRecieptsCopy;
      };

      return {
        ...state,
        getRecieptsData: setOrUpdateReciepts(),
      };
    }
    case actionTypes.SET_ACTION_TYPE:
      return {
        ...state,
        expenseCaseID: action.data.expenseCaseID,
      };
    case actionTypes.UPDATE_RECIPT_DATA:
      return {
        ...state,
        getRecieptsData: action.data.resubmitRecipts,
      };
    case actionTypes.OPEN_CASE_FOR_RESUBMIT_SUCCESS:
      return {
        ...state,
        expensesResubmitData:
          (action.cases.data1 &&
            action.cases.data1.CaseInfo &&
            action.cases.data1.CaseInfo[0]) ||
          {},
        selectedTransactions: [],
        expensesResubmitSucess: true,
        expenseEditSave:
          action?.cases?.payload?.actionType === 'Save' ||
          action?.cases?.payload?.actionType === 'RecallExpense',
        IsYodleeInitiated: action?.cases?.IsYodleeInitiated || false,
        resubmitLoader: false,
        expenseCaseID: action?.cases?.data1?.CaseInfo[0]?.id || '',
      };
    case actionTypes.OPEN_CASE_FOR_RESUBMIT_FAILURE:
      return {
        ...state,
        expensesResubmitData: {},
        resubmitLoader: false,
        expensesResubmitSucess: false,
      };

    case actionTypes.MAP_EXPENSE_LINEITEMS:
      return {
        ...state,
        expensesResubmitData: {
          expenses: action?.data?.expenses,
          project: action?.data?.project,
          businessreason: action?.data?.businessReason,
        },
        SelectedAccount: [action?.data?.SelectedAccount],
      };

    case actionTypes.EXPENSE_WITHDRAW_SUCCESS:
      return {
        ...state,
        expensesWithdrawSucess: true,
        resubmitLoader: false,
        expenseType: 'ManualExpense',
      };
    case actionTypes.EXPENSE_WITHDRAW_FAILURE:
      return {
        ...state,
        expensesWithdrawSucess: false,
        resubmitLoader: false,
      };

    case actionTypes.OPEN_CASE_FOR_RESUBMIT_LOADER:
      return {
        ...state,
        resubmitLoader: true,
      };

    case actionTypes.CLEAR_RESUBMIT_DATA:
      return {
        ...state,
        expensesResubmitData: {},
        ResubmissionNotes: '',
        expenseCaseID: '',
        convertedCurrency: { Result: '', data: [] },
        expensesWithdrawSucess: false,
        expensesResubmitSucess: false,
        IsYodleeInitiated: false,
        resetFormData: 'back',
        selectedTransactions: [],
        expenseLineItems: [],
        expenseEditSave: false,
      };

    case actionTypes.SET_EXPENSE_TYPE:
      return {
        ...state,
        expenseType: action.data.expenseType,
        oldSelectionModel: action.data.selectionModel
          ? action.data.selectionModel
          : state.oldSelectionModel,
        IsYodleeInitiated: action?.data?.IsYodleeInitiated || false,
      };

    case actionTypes.GENERATE_TOKEN_SUCCESS:
      return {
        ...state,
        generateTokenLoader: false,
        FastLinkURL: action?.cases?.GetAccessToken?.FastLinkURL || '',
        accessToken: action?.cases?.GetAccessToken?.accessToken || '',
        IsYodleeOpen: true,
        expenseType:
          action.cases &&
          action.cases.GetAccessToken &&
          action.cases.GetAccessToken.FastLinkURL &&
          action.cases.GetAccessToken.accessToken
            ? state.expenseType
            : 'ManualExpense',
      };
    case actionTypes.GENERATE_TOKEN_FAILURE:
      return {
        ...state,
        generateTokenLoader: false,
        FastLinkURL: '',
        accessToken: '',
      };
    case actionTypes.GENERATE_TOKEN_LOADER:
      return {
        ...state,
        generateTokenLoader: true,
      };

    case actionTypes.LINK_ACCOUNT_SUCCESS:
      return {
        ...state,
        linkAccountLoader: false,
        OpenBankingExists: true,
        Employeeloginexists: true,
        expenseType: 'ManualExpense',
      };
    case actionTypes.LINK_ACCOUNT_FAILURE:
      return {
        ...state,
        linkAccountLoader: false,
      };
    case actionTypes.LINK_ACCOUNT_LOADER:
      return {
        ...state,
        linkAccountLoader: true,
      };
    case actionTypes.SET_IS_YODLEE_OPEN:
      return {
        ...state,
        IsYodleeOpen: action.data.IsYodleeOpen,
      };

    case actionTypes.GET_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        yodleeTransactions: action?.cases?.Transactions || [],
        getTransactionsLoader: false,
      };
    case actionTypes.GET_TRANSACTIONS_FAILURE:
      return {
        ...state,
        yodleeTransactions: [],
        getTransactionsLoader: false,
      };
    case actionTypes.GET_TRANSACTIONS_LOADER:
      return {
        ...state,
        getTransactionsLoader: true,
      };

    case actionTypes.SET_SELECTED_TRANSACTIONS:
      return {
        ...state,
        selectedTransactions: action.data.selectedTransactions,
        oldSelectionModel: action.data.selectionModel,
      };

    case actionTypes.GET_ACCOUNTS_SUCCESS:
      return {
        ...state,
        linkedAccounts: action?.cases?.AccountDetails || [],
        getAccountsLoader: false,
      };
    case actionTypes.GET_ACCOUNTS_FAILURE:
      return {
        ...state,
        linkedAccounts: [],
        getAccountsLoader: false,
      };
    case actionTypes.GET_ACCOUNTS_LOADER:
      return {
        ...state,
        getAccountsLoader: true,
      };

    case actionTypes.GET_LINE_ITEMS:
      return {
        ...state,
        expenseLineItems: action.data.expenseLineItems,
        businessReason: action.data.businessReason ? action.data.businessReason : '',
        project: action.data.project ? action.data.project : '',
      };

    case actionTypes.SET_ACCOUNT_DETAILS:
      return {
        ...state,
        AccountsData: action.data,
      };

    case actionTypes.REGISTER_USER_SUCCESS:
      return {
        ...state,
        FastLinkURL: action?.cases?.GetAccessToken?.FastLinkURL || '',
        accessToken: action?.cases?.GetAccessToken?.accessToken || '',
        registerUserLoader: false,
        IsYodleeOpen: true,
        expenseType:
          action.cases &&
          action.cases.GetAccessToken &&
          action.cases.GetAccessToken.FastLinkURL &&
          action.cases.GetAccessToken.accessToken
            ? state.expenseType
            : 'ManualExpense',
      };

    case actionTypes.REGISTER_USER_FAILURE:
      return {
        ...state,
        registerUserLoader: false,
      };

    case actionTypes.REGISTER_USER_LOADER:
      return {
        ...state,
        registerUserLoader: true,
      };

    case actionTypes.DOWNLOAD_RECIEPT_SUCCESS:
      return {
        ...state,
        imageData: action?.cases,
        ExpenseAuditHistory: [],
        downloadImageLoader: false,
      };
    case actionTypes.VIEW_RECIEPT_SUCCESS:
      return {
        ...state,
        attachmentsData: action?.cases,
        ExpenseAuditHistory: [],
        downloadImageLoader: false,
      };

    case actionTypes.DOWNLOAD_RECIEPT_FAILURE:
      return {
        ...state,
        imageData: [],
        attachmentsData: [],
        downloadImageLoader: false,
      };

    case actionTypes.DOWNLOAD_RECIEPT_LOADER:
      return {
        ...state,
        downloadImageLoader: true,
      };

    case actionTypes.SET_EXPENSE_IMAGE_DATA:
      return {
        ...state,
        ExpenseAuditHistory: [],
        imageData: [],
        attachmentsData: [],
      };

    case actionTypes.GET_EXPENSE_AUDIT_SUCCESS:
      return {
        ...state,
        expenseAuditLoader: false,
        imageData: [],
        attachmentsData: [],
        ExpenseAuditHistory: action?.cases?.data1?.CaseInfo[0]?.ExpenseAuditHistory || [],
      };
    case actionTypes.GET_EXPENSE_AUDIT_FAILURE:
      return {
        ...state,
        expenseAuditLoader: false,
      };
    case actionTypes.GET_EXPENSE_AUDIT_LOADER:
      return {
        ...state,
        expenseAuditLoader: true,
      };

    case actionTypes.UPDATE_RESUBMISSION_NOTES:
      return {
        ...state,
        ResubmissionNotes: action.cases.ResubmissionNotes,
      };
    case actionTypes.CLEAR_RESUBMISSION_NOTES:
      return {
        ...state,
        ResubmissionNotes: '',
      };

    case actionTypes.PAYMENT_RETRY_SUCCESS:
      return {
        ...state,
        paymentRetryLoader: false,
      };
    case actionTypes.PAYMENT_RETRY_FAILURE:
      return {
        ...state,
        paymentRetryLoader: false,
      };
    case actionTypes.PAYMENT_RETRY_LOADER:
      return {
        ...state,
        paymentRetryLoader: true,
      };

    case actionTypes.UPDATE_YODLEE_TRANSACTION_ATTACHMENT:
      return {
        ...state,
        yodleeTransactionsAttachments: action.data || {},
      };

    case actionTypes.UPDATE_PAGE_INDEX:
      return {
        ...state,
        PageNumber: action.data.PageNumber || 1,
      };

    default:
      return state;
  }
}

import { actionTypes } from '../actions/actionTypes/timeoffActionType';

let initialState = {
  getTimeOffList: '',
  resetForm: false,
  getTeamTimeOffList: '',
  showLeaveBalance: [],
  showHolidays: [],
  pagination: 0,
  showLoader: false,
  showHolidaysLoader: false,
  showLeavesLoader: false,
  showLoaderForTeamTimeoff: false,
  LeaveBalanceHistory: [],
  openWarnig: false,
  widthdrawLoader: false,
  getTimeOffInfo: {},
};

/**
 * Redux reducers.
 * Used to update state in the store after actions are issued.
 */
export function timeOff(state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET_TIMEOFF_SUCCESS:
      return {
        ...state,
        getTimeOffList: action.cases.CaseInfo,
        getTimeOffInfo: action.cases,
        showLeaveBalance: action.cases.LeaveBalance,
        LeaveBalanceHistory: action.cases.LeaveBalanceHistory,
        showHolidays: action.cases.ClosedDates,
        pagination: parseInt(action?.cases?.PageIndexCount),
        showHolidaysLoader: false,
        showLeavesLoader: false,
        showLoaderForTeamTimeoff: false,
      };

    case actionTypes.GET_TIMEOFF_FAILURE:
      return {
        ...state,
        getTimeOffList: [],
        showLeaveBalance: [],
        showHolidays: [],
        pagination: 0,
        showHolidaysLoader: false,
        showLeavesLoader: false,
        showLoaderForTeamTimeoff: false,
      };

    case actionTypes.SHOW_LOADER_TEAM_TIMEOFF_LEAVES:
      return {
        ...state,
        showLoaderForTeamTimeoff: true,
      };

    case actionTypes.WITHDRAW_LOADER:
      return {
        ...state,
        withdrawLoader: true,
      };

    case actionTypes.POST_TIMEOFF_SUCCESS:
      return {
        ...state,
        getTimeOffList: (action.cases && action.cases.CaseInfo) || [],
        pagination: parseInt(action?.cases?.PageIndexCount || 0),
        showLeaveBalance: action?.cases?.LeaveBalance || [],
        LeaveBalanceHistory: action.cases.LeaveBalanceHistory,
        resetForm:
          action?.cases?.pyErrorCode === 'error' || action?.cases?.pyErrorMessage
            ? false
            : true,
        showLoader: false,
        openWarnig: false,
        SendNotifiation: '',
        withdrawLoader: false,
      };

    case actionTypes.TIMEOFF_WARNING:
      return {
        ...state,
        getTimeOffList: (action.cases && action.cases.CaseInfo) || [],
        resetForm: false,
        showLoader: false,
        openWarnig: true,
        SendNotifiation: action?.cases?.SendNotifiation || '',
      };

    case actionTypes.POST_TIMEOFF_FAILURE:
      return {
        ...state,
        showLoader: false,
        resetForm: false,
      };

    case actionTypes.GET_TEAM_TIMEOFF_SUCCESS:
      return {
        ...state,
        getTeamTimeOffList: (action.cases && action.cases.CaseInfo) || [],
        showLoaderForTeamTimeoff: false,
        pagination: parseInt(action?.cases?.PageIndexCount || 0),
      };

    case actionTypes.GET_TEAM_TIMEOFF_FAILURE:
      return {
        ...state,
        showLoaderForTeamTimeoff: false,
        pagination: 0,
      };

    case actionTypes.POST_TEAM_STATUS_SUCCESS:
      return {
        ...state,
        getTeamTimeOffList: (action.cases.data1 && action.cases.data1.CaseInfo) || [],
        showLoaderForTimeoffStatus: false,
        pagination: parseInt(action?.cases?.data1?.PageIndexCount || 0),
      };

    case actionTypes.POST_TEAM_STATUS_FAILURE:
      return {
        ...state,
        showLoaderForTimeoffStatus: false,
      };

    case actionTypes.SHOW_LOADER_TIMEOFF:
      return {
        ...state,
        showLoader: true,
        resetForm: false,
      };

    case actionTypes.SHOW_LOADER_TIMEOFF_HOLIDAYS:
      return {
        ...state,
        showHolidaysLoader: true,
      };
    case actionTypes.SHOW_LOADER_TIMEOFF_LEAVES:
      return {
        ...state,
        showLeavesLoader: true,
      };
    case actionTypes.POST_TEAM_STATUS_TIMEOFF_LOADER:
      return {
        ...state,
        showLoaderForTimeoffStatus: action.id,
      };
    case actionTypes.SET_TIMEOFF_DATA:
      return {
        ...state,
        resetForm: false,
        openWarnig: false,
        SendNotifiation: '',
      };

    default:
      return state;
  }
}

import { actionTypes } from '../actions/actionTypes/PayrollDeductionsActionType';

let initialState = {
  InActiveDeductionList: [],
  ActiveDeductionList: [],
  EmployeeList: [],
  payRollDeductionsLoader: false,
  deleteDeductionsLoader: false,
  DeductionTypes: [],
  DeleteID: '',
  goToHome: false,
  addDeductionsLoader: false,
  auditHistory: [],
  auditHistoryLoader: false,
  addExemptionloader: false,
  ExemptionsList: [],
  ExemptionsAuditHistory: [],
  SubmitExemptionLoader: false,
  viewAuditLoader: false,
};

export function payRollDeductions(state = initialState, action) {
  switch (action.type) {
    case actionTypes.PAYROLL_DEDUCTIONS_SUCCESS:
      return {
        ...state,
        ActiveDeductionList: action?.cases?.ActiveDeductionList || [],
        InActiveDeductionList: action?.cases?.InActiveDeductionList || [],
        DeductionTypes: action?.cases?.DeductionTypes || [],
        EmployeeList: action?.cases?.EmployeeList || [],
        payRollDeductionsLoader: false,
        goToHome: false,
      };

    case actionTypes.PAYROLL_DEDUCTIONS_FAILURE:
      return {
        ...state,
        payRollDeductionsLoader: false,
      };

    case actionTypes.PAYROLL_DEDUCTIONS_LOADER:
      return {
        ...state,
        payRollDeductionsLoader: true,
      };

    case actionTypes.DELETE_DEDUCTION_SUCCESS:
      return {
        ...state,

        deleteDeductionsLoader: false,
        DeleteID: '',
      };

    case actionTypes.DELETE_DEDUCTIONS_FAILURE:
      return {
        ...state,
        DeleteID: false,
        deleteDeductionsLoader: false,
      };

    case actionTypes.DELETE_DEDUCTIONS_LOADER:
      return {
        ...state,
        deleteDeductionsLoader: true,
        DeleteID: action?.data?.id || '',
      };

    case actionTypes.ADD_NEW_DEDUCTIONS_SUCCESS:
      return {
        ...state,
        goToHome: action.data.pyNote ? true : false,
        addDeductionsLoader: false,
      };

    case actionTypes.ADD_NEW_DEDUCTIONS_FAILURE:
      return {
        ...state,
        addDeductionsLoader: false,
      };

    case actionTypes.ADD_NEW_DEDUCTIONS_LOADER:
      return {
        ...state,
        addDeductionsLoader: true,
      };
    case actionTypes.GET_AUDIT_HISTORY_SUCCESS:
      return {
        ...state,
        auditHistory: action?.data?.DeductionsAuditHistory || [],
        auditHistoryLoader: false,
      };

    case actionTypes.GET_AUDIT_HISTORY_FAILURE:
      return {
        ...state,
        auditHistoryLoader: false,
      };

    case actionTypes.GET_AUDIT_HISTORY_LOADER:
      return {
        ...state,
        auditHistoryLoader: true,
      };
    case actionTypes.ADD_EXEMPTIONS_SUCCESS:
      return {
        ...state,
        addExemptionloader: false,
        ExemptionsList: action?.data?.ExemptionsList || [],
        // ExemptionsAuditHistory: action?.data?.ExemptionsAuditHistory || [],
      };

    case actionTypes.ADD_EXEMPTIONS_FAILURE:
      return {
        ...state,
        addExemptionloader: false,
      };

    case actionTypes.ADD_EXEMPTIONS_LOADER:
      return {
        ...state,
        addExemptionloader: true,
      };

    case actionTypes.SUBMIT_EXEMPTIONS_SUCCESS:
      return {
        ...state,
        SubmitExemptionLoader: false,
        // ExemptionsList: action?.data?.ExemptionsList || [],
        // ExemptionsAuditHistory: action?.data?.ExemptionsAuditHistory || [],
      };

    case actionTypes.SUBMIT_EXEMPTIONS_FAILURE:
      return {
        ...state,
        SubmitExemptionLoader: false,
      };

    case actionTypes.SUBMIT_EXEMPTIONS_LOADER:
      return {
        ...state,
        SubmitExemptionLoader: true,
      };

    case actionTypes.RESET_EXEMPTION_LIST:
      return {
        ...state,
        ExemptionsList: [],
        ExemptionsAuditHistory:[]
      };
    case actionTypes.VIEW_AUDIT_SUCCESS:
      return {
        ...state,
        viewAuditLoader: false,
        ExemptionsAuditHistory: action?.data?.ExemptionsAuditHistory || [],
      };

    case actionTypes.VIEW_AUDIT_FAILURE:
      return {
        ...state,
        viewAuditLoader: false,
      };

    case actionTypes.VIEW_AUDIT_LOADER:
      return {
        ...state,
        viewAuditLoader: true,
      };

    default:
      return state;
  }
}

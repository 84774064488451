import { createTheme } from '@mui/material/styles';

const theme = createTheme();

const light = {
  '--type': 'light',
  '--timesheet-holidayBg': '#9ee0f8',
  '--timesheet-leaveBG': '#ffdcd0',
  '--approved-color': '#7bc228',
  '--pending-color': '#ffb706',
  '--rejected-color': '#35bfbf',
  '--fixedWhite': theme.palette.common.white,
  '--expense-deleteIcon': '#ff7951',
  '--textcolor-blueBayoux': '#445b78',
  '--background-panache': '#f1f9f2',
  '--pattensBlue': '#daeeff',
  '--aquaSpring': '#edf7ed',
  '--textcolor-bismark': '#546e7a',
  '--backGround-blackRussian': '#030617',
  '--tabsBg': theme.palette.grey[100],
  '--toolbar-color': theme.palette.primary.main,
  '--textBlackToWhite': theme.palette.common.black,
  '--borderBottom-before': '1px solid rgba(0, 0, 0, 0.2)',
  '--borderBottom-after': '1px solid rgba(0, 0, 0, 0.4)',
  '--activeBackGroudPageIcon': theme.palette.grey[300],
  '--year-holidays': theme.palette.primary.light,
  '--textWhiteToBlack': theme.palette.common.white,
  '--profile-background': theme.palette.grey[200],
  '--mobilemenuTextColor': '#546e7a',
};

const dark = {
  '--type': 'dark',
  '--timesheet-holidayBg': '#626669',
  '--timesheet-leaveBG': '#9f7e53',
  '--approved-color': '#7bc228',
  '--pending-color': '#ffb706',
  '--rejected-color': '#35bfbf',
  '--fixedWhite': theme.palette.common.white,
  '--expense-deleteIcon': '#a341d2',
  '--textcolor-blueBayoux': '#d0dff1',
  '--background-panache': '#f1f9f2',
  '--pattensBlue': '#110c0c',
  '--aquaSpring': '#edf7ed',
  '--textcolor-bismark': '#a9c9d8',
  '--backGround-blackRussian': '#030617',
  '--tabsBg': '#f3f3f3',
  '--toolbar-color': '#545454',
  '--textBlackToWhite': theme.palette.common.white,
  '--borderBottom-before': '1px solid rgba(248, 248, 250, 0.2)',
  '--borderBottom-after': '1px solid rgba(255, 255, 255, 0.4)',
  '--activeBackGroudPageIcon': '#626262',
  '--year-holidays': '#171616',
  '--textWhiteToBlack': theme.palette.common.black,
  '--profile-background': '#383838',
  '--mobilemenuTextColor': '#fff',
};

const defaultTheme = {
  AccordinHeaderBg: 'var(--tabsBg)',
  tabsBg: 'var(--tabsBg)',
  textBlackToWhite: 'var(--textBlackToWhite)',
  textWhiteToBlack: 'var(--textWhiteToBlack)',
  fixedWhite: 'var(--fixedWhite)',
  activeBackGroudPageIcon: 'var(--activeBackGroudPageIcon)',
  holidayBg: 'var(--timesheet-holidayBg)',
  leaveBG: 'var(--timesheet-leaveBG)',
  profileBackground: 'var(--profile-background)',
  mobilemenuTextColor: 'var(--mobilemenuTextColor)',
  lightBlueBackground: {
    background: 'var(--year-holidays) !important',
    color: `${theme.palette.common.white} !important`,
  },
  toolbar: {
    color: 'var(--toolbar-color)',
  },
  typography: {
    // fontFamily: 'Poppins, Arial, sans-serif',
    fontFamily: ['Inter', 'Roboto', '"Helvetica Neue"', 'Arial', 'sans-serif'].join(','),
    fontSize: 11,
    button: {
      textTransform: 'none',
    },
    subtitle1: {
      fontWeight: 500,
      color: 'var(--textcolor-bismark)',
    },
  },
  statuscolors: {
    approved: '#7bc228',
    pending: '#ffb706',
    rejected: '#f44336',
    withdrawn: '#c5d200',
    update: '#d9006e',
    New: '#09929a',
  },
  overrides: {
    MuiInput: {
      underline: {
        '&&&&:before': {
          borderBottom: 'var(--borderBottom-before)',
        },
        '&&&&:hover:before': {
          borderBottom: 'var(--borderBottom-after)',
        },
      },
      inputMultiline: {
        resize: 'both',
      },
    },
    MuiButton: {
      label: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
    },
    MuiPaper: {
      root: {
          boxShadow:'none'
      },
  },
    MuiSelect: {
      root: {
        display: 'flex',
        alignItems: 'center',
      },
    },
    MuiListItemIcon: {
      root: {
        minWidth: theme.spacing(4),
      },
    },
    MuiListItem: {
      root: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
      },
    },
    MuiFormLabel: {
      root: {
        fontSize: theme.typography.body2,
      },
    },

    MuiTableCell: {
      root: {},
    },
    MuiToolbar: {
      gutters: {
        [theme.breakpoints.up('sm')]: {
          paddingLeft: theme.spacing(2),
          paddingRight: theme.spacing(2),
        },
      },
    },
    MuiAccordion: {
      root: {
        '&&:before': {
          backgroundColor: 'transparent',
        },
      },
    },
  },
  palette: {
    mode: 'light',
    customColors: {
      darkOrchid: 'var(--expense-deleteIcon)',
      blueBayoux: 'var(--textcolor-blueBayoux)',
      Panache: 'var(--background-panache)',
      pattensBlue: 'var(--pattensBlue)',
      aquaSpring: 'var(--aquaSpring)',
      bismark: 'var(--textcolor-bismark)',
      blackRussian: 'var(--backGround-blackRussian)',
    },
    customBoxShadows: {
      boxShadow: 'rgb(0 0 0 / 16%) 0px 1px 4px',
    },
  },
};

export { defaultTheme, light, dark };
